<!-- 关于我们 -->
<template>
  <div class="about">
    <img class="banner" src="@/assets/images/about/banner.jpg" alt="">
    <v-tabs class="w1200">
      <v-tab @click="$vuetify.goTo('#companyDesc')">企业简介</v-tab>
      <v-tab @click="$vuetify.goTo('#qualifications')">专利资质</v-tab>
      <v-tab @click="$vuetify.goTo('#corporateCulture')">企业文化</v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <div id="companyDesc" class="company-desc w1200 clearfix">
      <div class="left fl">
        <img src="@/assets/images/about/about01.png" alt="">
      </div>

      <div class="right fl">
        <p class="title-p">企业简介</p>
        <pre>安徽配友软件有限公司是一家为汽配商互联网营销提供专业技术支持的IT服
务商，是中国汽配后市场数字营销的先行者，致力于为客户提供专业、高
效的解决方案，助力企业实现市场目标。
公司产品均独立研发，拥有自主知识产权。公司高管平均在互联网、汽车
配件和工程机械领域持续深耕20多年，凭借深厚的专业背景和持续创新。
2023年推出了独具特色的汽配数字营销系统，以可靠数据及营销工具为基
础，连接企业、员工、客户，形成业务闭环，助力企业实现数字化营销。</pre>
        <div class="hover-div">
          <img src="@/assets/images/about/about1_1.jpg" alt="">
          <img src="@/assets/images/about/about1_2.jpg" alt="">
        </div>
      </div>
    </div>

    <div id="qualifications" class="qualifications">
      <div class="w1200">
        <p class="title-p">资质专利</p>
      </div>
      <img src="@/assets/images/about/about02.png" alt="">
    </div>

    <div id="corporateCulture" class="corporateCulture clearfix">
      <div class="w1200">
        <div class="left fl">
          <p class="title-p">企业文化</p>
          <ul>
            <li><span>价&ensp;值&ensp;观 :</span>用户为先、创新为本</li>
            <li><span>管理理念 :</span>多引导，少布置；多检查，少研究</li>
            <li><span>人才理念 :</span>能力适度，操守为先；敬业为本，团队第一</li>
            <li><span>工作作风 :</span>主动思考、迅速反应、立即行动</li>
          </ul>
        </div>
        <div class="right fr">
          <img src="@/assets/images/about/about03.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return {
      title: "企业-配友软件",
      // meta: [
      //   { charset: "utf-8" },
      //   { name: "description", content: "安徽配友软件有限公司是一家为汽车配件商互联网营销提供专业技术支持的IT服务商，是中国汽车配件后市场数字营销的先行者，致力于为客户提供专业、高效的解决方案，助力企业实现市场目标" },
      // ],
    };
  },
  data() {
    return {}
  },
  created(){
    document.querySelector('meta[name="description"]').setAttribute('content',"安徽配友软件有限公司是一家为汽车配件商互联网营销提供专业技术支持的IT服务商，是中国汽车配件后市场数字营销的先行者，致力于为客户提供专业、高效的解决方案，助力企业实现市场目标")
  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.about {

  :deep .v-tabs {
    .v-tabs-bar {
      height: 60px;
    }

    // .v-tab--active {
    // font-weight: bold;
    // }

    .v-tab {
      padding: 0;
      color: #333 !important;
      font-size: 20px;
      margin-right: 60px;
    }

    .v-tabs-slider-wrapper {
      height: 4px;
      border-radius: 1px;
    }
  }

  p.title-p {
    font-size: 32px;
    line-height: 32px;
    font-weight: 700;
  }

  .company-desc {
    padding: 68px 0 80px;

    .left {
      width: 600px;
      height: 454px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .right {
      width: 530px;
      margin-left: 50px;
      color: #333333;

      p.title-p {
        margin-bottom: 30px;
      }

      pre {
        font-family: PingFang-SC-Medium;
        font-size: 16px;
        line-height: 36px;
      }

      .hover-div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
      }

      img {
        box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);

        &:hover {
          transition: transform .5s ease-out;
          -o-transition: transform .5s ease-out;
          -webkit-transition: transform .5s ease-out;
          -moz-transition: transform .5s ease-out;
          transform: scale(1.1);
          -ms-transform: scale(1.1);
          -webkit-transform: scale(1.1);
        }
      }
    }
  }

  .qualifications {
    background-color: #ebf0f6;
    overflow: hidden;
    padding-bottom: 40px;
    width: 100%;
    height: 667px;
    background: url("../../assets/images/about/about02.png") no-repeat center center;
    background-size: 1920px 667px;

    p.title-p {
      text-align: center;
      margin-bottom: 50px;
      margin-top: 60px;
    }

    img {
      display: none;
      width: 1200px;
      height: 475px;
    }
  }

  .corporateCulture {
    overflow: hidden;
    padding-bottom: 32px;

    .left {
      p.title-p {
        margin-bottom: 58px;
        margin-top: 118px;
      }

      ul {
        padding: 0;

        li {
          font-size: 16px;
          line-height: 20px;
          color: #333;
          margin-bottom: 40px;

          span {
            font-size: 20px;
            margin-right: 15px;
          }
        }
      }
    }

    .right {
      width: 560px;
      height: 360px;
      margin-top: 80px;

      img {
        width: 100%;
        height: 100%;
      }
    }

  }
}
</style>